import { useState } from "react";
import logoEdtools from "../../../assets/logo-grey.png";
import DropdownUser from "../DropdowUser/DropdowUser";

export const Navbar = ({ isSidebarOpen }) => {
  const sidebarWidth = isSidebarOpen ? "0px" : "0px"; // Ajusta estos valores según el ancho real del Sidebar y SidebarContent

  return (
    <nav
      className=" border-gray-200  bg-white dark:bg-gray-900 dark:border-gray-700"
      style={{
        marginLeft: sidebarWidth, // Desplaza el Navbar hacia la derecha
        width: `calc(100% - ${sidebarWidth})`, // Resta el ancho del Sidebar al ancho total
      }}
    >
      <div className="lg:px-5 lg:pl-3 flex items-center justify-between">
        <div className="flex items-center">
          <a href="/" className="flex items-center p-0 m-1">
            <img src={logoEdtools} className="h-14 mr-9" alt="Edtools Logo" />
          </a>
        </div>
        <div className="flex items-center justify-end">
          <DropdownUser />
        </div>
      </div>
    </nav>
  );
};
