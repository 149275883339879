import { Button, message } from "antd";
import { useState } from "react";
import { AiOutlineMore } from "react-icons/ai";
import "bootstrap-icons/font/bootstrap-icons.css";
import { fetchArchiveThread, fetchRenameThread } from "../../services/api";

export const CardThread = ({ thread, formatDate, onSelectThread, onUpdateThreadName }) => {
  const [menusState, setMenusState] = useState({}); // Manejo del estado del menú
  const [editingThreadId, setEditingThreadId] = useState(null); // Control del hilo en edición
  const [newName, setNewName] = useState(""); // Nuevo nombre para el hilo
  const [threads, setThreads] = useState([]);  

  // Toggle menu visibility
  const toggleMenu = (threadId) => {
    setMenusState((prev) => ({
      ...prev,
      [threadId]: !prev[threadId], // Alterna el estado del menú para este hilo
    }));
  };

  const handleRename = async () => {
    if (!newName.trim()) {
      message.error("El nombre no puede estar vacío");
      return;
    }
  
    try {
      const updatedThread = await fetchRenameThread(thread.id, newName);
  
      if (updatedThread) {
        // Actualiza el nombre localmente
        onUpdateThreadName(thread.id, newName);
        message.success("Thread renombrado correctamente");
  
        // Limpia los estados de edición y cierra el menú
        setEditingThreadId(null);
        setNewName("");
        setMenusState((prev) => ({
          ...prev,
          [thread.attributes.id_value]: false,
        }));
      }
    } catch (error) {
      message.error(`Error al renombrar el thread: ${error.message}`);
    }
  };
  

  const handleArchive = async () => {
    try {
      const archivedThread = await fetchArchiveThread(thread.id);
      if (archivedThread) {
        message.success("Thread archivado correctamente");
  
        // Notifica al componente padre para actualizar la lista de threads
        onUpdateThreadName(thread.id, null, "archived");
  
        // Cierra el menú
        setMenusState((prev) => ({
          ...prev,
          [thread.id]: false,
        }));
      }
    } catch (error) {
      message.error(`Error al archivar el thread: ${error.message}`);
    }
  };
  
  

  return (
    <div
      className="border border-slate-300 rounded-lg p-4 mt-4 mb-4 hover:cursor-pointer transition-shadow duration-300"
      onClick={() => onSelectThread(thread.attributes.id_value)} // Usa onSelectThread aquí
    >
      <div className="grid grid-cols-[5fr_1fr] items-center w-full relative">
      {editingThreadId === thread.attributes.id_value ? (
  <div
    onBlur={(e) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        // Solo cierra la edición si el clic fue fuera del contenedor
        setEditingThreadId(null);
      }
    }}
    tabIndex={-1} // Permite que este contenedor capture el foco
  >
    <input
      value={newName}
      onChange={(e) => setNewName(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          handleRename();
        }
      }}
      className="text-sm text-black truncate w-full rounded-sm"
    />
    <Button
      onClick={(e) => {
        e.preventDefault();
        handleRename();
      }}
      className="w-full bg-slate-300 mt-2"
    >
      Guardar
      <i className="bi bi-save ml-2"></i>
    </Button>
  </div>
) : (
  <div className="flex flex-col max-w-full pr-4 overflow-hidden">
    <h1 className="text-sm font-semibold text-white truncate w-full">
      {thread.attributes.name || thread.attributes.id_value}
    </h1>
        <h2 className="text-sm text-gray-500 mt-2 truncate w-full">
      {formatDate(thread.attributes.createdAt)}
    </h2>
  </div>
)}


<div className="flex justify-end relative">
  <AiOutlineMore
    className="hover:cursor-pointer text-white w-8 h-8"
    onClick={(e) => {
      e.stopPropagation();
      toggleMenu(thread.attributes.id_value);
    }}
  />

  {/* Menú con opciones */}
  {menusState[thread.attributes.id_value] && editingThreadId !== thread.attributes.id_value && (
    <div className="absolute bg-white shadow-lg mt-10 rounded-lg right-0 p-2 flex flex-col space-y-2 z-10">
      <Button
        onClick={() => {
          setEditingThreadId(thread.attributes.id_value);
          setNewName(thread.attributes.name || "");
        }}
        className="w-full"
      >
        Renombrar
        <i className="bi bi-pencil ml-2"></i>
      </Button>
      <Button
        onClick={() => handleArchive(thread.attributes.id_value)}
        className="w-full"
      >
        Archivar
        <i className="bi bi-archive ms-2"></i>
      </Button>
    </div>
  )}
</div>

      </div>
    </div>
  );
};
