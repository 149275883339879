import {
  LuChevronLeftSquare,
  LuChevronRight,
  LuHome,
  LuMessagesSquare,
  LuSettings,
  LuUsers,
} from "react-icons/lu";
import { Link } from "react-router-dom";

export const Sidebar = ({
  isOpen,
  toggleSidebar,
  setSelectedContent,
  selectedContent,
}) => {
  const getButtonClass = (content) => {
    return `transition-colors duration-200 rounded-lg ${
      selectedContent === content
        ? "text-blue-500 bg-blue-100 dark:text-blue-400 dark:bg-gray-800"
        : "text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
    }`;
  };
  return (
    <div
    className={`flex flex-col space-y-24 items-center h-screen py-8 bg-white dark:bg-gray-900 dark:border-gray-700 transition-all duration-300 ${
      isOpen ? "w-16 translate-x-0" : "w-0 -translate-x-3 overflow-visible"
    } md:w-16 md:translate-x-0`}
  >

      <button
        onClick={toggleSidebar}
        className={`text-gray-500  dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 fixed top-2/4 rounded-sm ${
          isOpen ? "left-8  transition-all duration-300" : "left-1"
        } p-0.5 bg-gray-800 rounded-xl md:left-12`}
      >
        {isOpen ? (
          <LuChevronLeftSquare size={20} className="justify-center" />
        ) : (
          <LuChevronRight size={20} className="justify-center" />
        )}
      </button>

      <nav
  className={`flex flex-col items-center flex-1 space-y-8 md:block${
    isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
  } transition-opacity duration-300`}
>
        <button
          onClick={() => {
            setSelectedContent("home");
            if (!isOpen) toggleSidebar(); 
          }}
          className={getButtonClass("home")}
        >
          <Link to="/home">
          <LuHome size={20} />
          </Link>
        </button>
       
        <button
         onClick={() => setSelectedContent("messages")}
          className={getButtonClass("messages")}
        >
          <Link to="/history">
            <LuMessagesSquare size={20} />
          </Link>
        </button>

        <button
          onClick={() => setSelectedContent("users")}
          className={getButtonClass("users")}
        >
          <Link to="/Profile">
          <LuUsers size={20} />
          </Link>
        </button>
      </nav>
      <Link
        href="#"
        className="p-1.5 inline-block text-gray-500 focus:outline-nones transition-colors duration-200 rounded-lg dark:text-gray-400 dark:hover:bg-gray-800 hover:bg-gray-100"
      >
        <LuSettings size={20} />
      </Link>
    </div>
  );
};
