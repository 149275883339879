import { message } from "antd";
import { envs } from "../config";
import { getToken } from "../helpers";
import OpenAI from "openai";


export const fetchOccupations = async () => {
  try {
    const response = await fetch(`${envs.api}/occupations`); 
    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.status}`);
    }
    const data = await response.json();
    return data.data; 
  } catch (error) {
    console.error("Error fetching occupations:", error);
    throw error; 
  }
};


export const fetchCodeCountries = async () => {
  try {
    const response = await fetch(`${envs.api}/code-countries`);
    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.status}`);
    }
    const data = await response.json();
    return data.data; 
  } catch (error) {
    console.error("Error fetching code countries:", error);
    throw error; 
  }
};


export const updateUserProfile = async (userId, data) => {
  try {
    const response = await fetch(`${envs.api}/users/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${envs.bearer} ${getToken()}`,
      },
      body: JSON.stringify(data),
    });
    const cloneResponse = response.clone();

    return await cloneResponse.json();
  } catch (error) {
    console.error("Error updating profile:", error);
    throw new error();
  }
};


const openai = new OpenAI({
  apiKey: envs.openaiKey,
  dangerouslyAllowBrowser: true,
});

export const createAndRunThread = async (assistantId, input) => {

  console.log(assistantId);
  console.log(input);
  
  
  const response = await openai.beta.threads.createAndRun({
    assistant_id: assistantId,
    thread: {
      messages: [{ role: "user", content: input }],
    },
  });
  return response;
};

// Función para obtener el estado de un thread
export const getThreadStatus = async (threadId) => {
  const response = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${envs.bearer} ${process.env.REACT_APP_OPENAI_KEY}`,
      "OpenAI-Beta": "assistants=v2",
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Error obteniendo el estado del thread");
  }

  return response.json();
};

// Función para obtener los mensajes de un thread
export const getThreadMessages = async (threadId) => {
  const messages = await openai.beta.threads.messages.list(threadId);
  return messages;
};

// Función para guardar un thread en Strapi
export const saveThreadInStrapi = async (threadId, createdAt, userId) => {
  const response = await fetch(`${envs.api}/open-ai-threads`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${envs.bearer} ${getToken()}`,
    },
    body: JSON.stringify({
      data: {
        id_value: threadId,
        createdat: createdAt,
        users_permissions_user: userId,
      },
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error("Error guardando el thread en Strapi");
  }

  return response.json();
};

// Función para crear un mensaje en un thread existente
export const createMessage = async (threadId, input) => {
  const response = await openai.beta.threads.messages.create(threadId, {
    role: "user",
    content: input,
  });
  return response;
};

// Función para iniciar un nuevo run en un thread existente
export const startRun = async (threadId, assistantId) => {
  const response = await openai.beta.threads.runs.create(threadId, {
    assistant_id: assistantId,
  });
  return response;
};

export const fetchThreads = async (userId) => {
  console.log("Token enviado todos los hilos:", getToken());
  try {
    const response = await fetch(
      `${envs.api}/open-ai-threads?filters[users_permissions_user][$eq]=${userId}`,
      {
        headers: {
          Authorization: `${envs.bearer} ${getToken()}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Error al obtener los threads");
    }
    return response.json();
  } catch (error) {
    message.error(`Error al cargar threads: ${error.message}`);
  }
};



// Manejar la selección de un thread y cargar mensajes con carga perezosa
export const fetchSelectThreadId = async (threadId) => {
  try {
    const response = await openai.beta.threads.messages.list(threadId, { limit: 10 });

    if (!response.ok) {
      throw new Error(`Error al obtener los mensajes para el thread ${threadId}: ${response.statusText}`);
    }

    const { data } = await response.json();
    return data; // Devuelve los datos si todo es correcto
  } catch (error) {
    message.error(`Error al cargar mensajes: ${error.message}`);
    return null; // Devuelve null o un valor predeterminado en caso de error
  }
};

export const fetchRenameThread = async (id, newName) => {
  if (!newName.trim()) {
    message.error("El nombre no puede estar vacío");
    return null; // Asegurarse de no proceder si el nombre está vacío
  }

  try {
    const response = await fetch(`${envs.api}/open-ai-threads/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${envs.bearer} ${getToken()}`,
       
      },
          body: JSON.stringify({ data: { name: newName } }), // Enviar el nuevo nombre en el cuerpo
    });
   
    if (!response.ok) {
      throw new Error(`Error al renombrar el thread: ${response.statusText}`);
    }

    const { data } = await response.json();
    
    return data; // Devuelve los datos del thread actualizado
  } catch (error) {
    message.error(`Error al renombrar el thread: ${error.message}`);
    return null; // Retorna null en caso de error
  }
};

export const fetchArchiveThread = async (id) => {
  try {
    const response = await fetch(`${envs.api}/open-ai-threads/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${envs.bearer} ${getToken()}`,
      },
      body: JSON.stringify({data: { status: "archived" }}), 
    });

    if (!response.ok) {
      throw new Error(`Error al archivar el thread: ${response.statusText}`);
    }

    const { data } = await response.json();
    return data; // Devuelve los datos del thread actualizado
  } catch (error) {
    message.error(`Error al archivar el thread: ${error.message}`);
    return null; // Retorna null en caso de error
  }
};

