import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { setToken } from "../../helpers";
import { envs } from "../../config";

const SignUp = () => {
  const { isDesktopView } = useScreenSize();
  const navigate = useNavigate();
  const { setUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      // Reemplazar username con email
      const submissionValues = {
        ...values,
        username: values.email, // Asignar email al campo username
      };

      console.log("Submitting registration with values:", submissionValues);

      const response = await fetch(`${envs.api}/auth/local/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(submissionValues),
      });

      const data = await response.json();
      console.log("Received response from API:", data);

      if (data?.error) {
        throw data?.error;
      } else {
        setToken(data.jwt);
        setUser(data.user);
        console.log(data.user)
        message.success(`Bienvenido a Edtools ${data.user.name}!`);
        navigate("/profile", { replace: true });
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setError(error?.message ?? "Algo malo sucedió!");
    } finally {
      setIsLoading(false);
    }
  };

  const validatePassword = (_, value) => {
    const passwordRegex = /^(?=.*\d).{8,}$/;
    if (!value) {
      return Promise.reject(new Error("Por favor, ingrese una contraseña"));
    }
    if (!passwordRegex.test(value)) {
      return Promise.reject(
        new Error(
          "La contraseña debe tener al menos 8 caracteres y contener al menos un número"
        )
      );
    }
    return Promise.resolve();
  };

  const compareToFirstPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Las contraseñas no coinciden!"));
    },
  });

  return (
    <Fragment>
      <Row align="middle h-screen overflow-hidden flex">
        <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0} className="flex items-center justify-center">
          <Card className="w-full border shadow-gray-400 shadow-lg" title="Registrarse en Edtools">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError("")}
              />
            ) : null}
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >

              {/* Campo de username oculto */}
              <Form.Item
                label="Nombre de usuario"
                name="username"
                rules={[
                  {
                    required: false,
                    type: "string",
                  },
                ]}
                hidden={true} // Ocultar el campo
              >
                <Input placeholder="Username" />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email address" />
              </Form.Item>

              <Form.Item
                label="Contraseña"
                name="password"
                rules={[
                  {
                    required: true,
                    validator: validatePassword,
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item
                label="Confirmar contraseña"
                name="confirmPassword"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Por favor, confirme su contraseña",
                  },
                  compareToFirstPassword,
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>

              <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:opacity-50"
                >
                  Registrarse {isLoading && <Spin size="small" />}
                </Button>
              </Form.Item>
            </Form>
            <Typography.Paragraph className="form_help_text">
              ¿Ya tienes una cuenta? <Link to="/signin">Ingresar</Link>
            </Typography.Paragraph>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SignUp;
