// Message.jsx
import React, { useState, useEffect } from 'react';
import { Button, message, Spin } from 'antd';
import { fetchSelectThreadId } from '../../services/api'; // Asegúrate de que esta función está implementada correctamente
import OpenAI from 'openai';

export const Message = ({ selectedThreadId }) => {
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_KEY, dangerouslyAllowBrowser: true });

  useEffect(() => {
    if (!selectedThreadId) return; // No hacer nada si no hay un thread seleccionado

    const loadMessages = async () => {
      setLoadingMessages(true);
      setMessages([]); // Limpiar los mensajes anteriores
      setHasMoreMessages(true); // Resetear el estado de más mensajes por cargar

      try {
        console.log("Cargando mensajes para el thread con ID:", selectedThreadId);
        const threadMessages = await openai.beta.threads.messages.list(selectedThreadId, { limit: 10 });
        setMessages(threadMessages.data);

        if (threadMessages.data.length < 10) {
          setHasMoreMessages(false);
        }
      } catch (error) {
        message.error(`Error al cargar mensajes: ${error.message}`);
      } finally {
        setLoadingMessages(false);
      }
    };

    loadMessages();
  }, [selectedThreadId]); // Solo recargar cuando el selectedThreadId cambie

  return (
    <div className="flex flex-col items-center justify-center p-8">
    <h1 className="font-semibold text-2xl text-gray-800 mt-8 mb-6 p-4 rounded-md">
  Mensajes
</h1>

      {loadingMessages && <Spin tip="Cargando mensajes..." />}
      {selectedThreadId && messages.length > 0 ? (
        <div className='text-sm'>
          {messages.map((msg, index) => (
            <div key={index}>
              <p>{msg.role}: {msg.content[0]?.text?.value || ''}</p> {/* Ajusta esto según el formato de los mensajes */}
            </div>
          ))}
        </div>
      ) : (
        <div className='mt-12'>No hay mensajes disponibles.</div>
      )}
      {hasMoreMessages && !loadingMessages && (
        <Button onClick={() => {/* Lógica para cargar más mensajes */}} className="mt-5">
          Cargar más mensajes
        </Button>
      )}
    </div>
  );
};
