import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { setToken } from "../../helpers";
import { envs } from "../../config";

const SignIn = () => {
  const { isDesktopView } = useScreenSize();
  
  const navigate = useNavigate();

  const { setUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const value = {
        identifier: values.email,
        password: values.password,
      };
      const response = await fetch(`${envs.api}/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });

      const data = await response.json();
      
      if (data?.error) {
        throw data?.error;
      } else {
        // set the token
        setToken(data.jwt);
        
        // set the user
        setUser(data.user);
        console.log('token', data.jwt)
        message.success(`Bienvenido(a) de nuevo ${data.user.name}`);

        navigate("/home", { replace: true });
      }
    } catch (error) {
      console.error(error);
      setError(error?.message ?? "Algo malo sucedió!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment >
      <Row
        align="middle"
        className="h-screen overflow-hidden flex" // Asegura que no haya scroll y ocupe toda la pantalla
      >
        <Col
          span={isDesktopView ? 8 : 24}
          offset={isDesktopView ? 8 : 0}
          className="flex items-center justify-center"
        >
          <Card className="w-full border shadow-gray-400 shadow-lg" title="Bienvenida(o) a Edtools">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError("")}
              />
            ) : null}
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              className="overflow-auto" // Asegura que el formulario no cause scroll
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input placeholder="Dirección de correo electrónico " />
              </Form.Item>
  
              <Form.Item
                label="Contraseña"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
  
              <Form.Item className="flex justify-center">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:opacity-50"
                  disabled={isLoading}
                >
                  Ingresar
                  {isLoading && (
                    <div className="inline-block ml-2 animate-spin rounded-full border-2 border-t-2 border-white w-4 h-4"></div>
                  )}
                </button>
              </Form.Item>
            </Form>
            <Typography.Paragraph className="form_help_text">
              ¿Todavía no tienes cuenta? <Link to="/signup">Regístrate</Link>
            </Typography.Paragraph>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SignIn;
