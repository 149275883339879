import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Profile from "./components/Profile/Profile";
// import SocialCards from "./components/SocialCards/SocialCards";
import { getToken } from "./helpers";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import Home from "./components/Home/Home";
import History from "./components/History/History";

const AppRoutes = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={getToken() ? <Home /> : <Navigate to="/signin" />}
        />

        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />

        <Route
          path="/home"
          element={getToken() ? <Home /> : <Navigate to="/signin" />}
        />
        <Route
          path="/profile"
          element={getToken() ? <Profile /> : <Navigate to="/signin" />}
        />
  
  <Route
      path="/history/:threadId" 
        element={getToken() ? <History /> : <Navigate to="/signin" />}
      />
     


        <Route
          path="/History"
          element={getToken() ? <History /> : <Navigate to="/signin" />}
        />
      </Routes>
    </div>
  );
};

export default AppRoutes;

