

export const Message = ({ text, sender }) => {
  const isUser = sender === "user";
  return (
    <div

      className={`flex ${isUser ? 'justify-end' : 'justify-start'} animate-fade-in`}
    >
      <div
        className={`max-w-[75%] px-4 py-3 rounded-lg text-sm shadow-lg transition-all duration-300 ${
          isUser
            ? 'bg-blue-500 text-white rounded-br-none'
            : 'bg-gray-200 text-gray-800 rounded-bl-none'
        }`}
      >
        {text}
      </div>
    </div>

  );
};
