import Joi from 'joi';


const envSchema = Joi.object({
  REACT_APP_OPENAI_KEY: Joi.string().required(),
  REACT_APP_ASSISTANT_ID: Joi.string().required(),
  REACT_APP_AVATAR_API: Joi.string().uri().required(),
  REACT_APP_API: Joi.string().uri().required(),
  REACT_APP_AUTH_TOKEN: Joi.string().required(),
  REACT_APP_BEARER: Joi.string().required(),
}).unknown(true);


const { error, value: validatedEnv } = envSchema.validate(process.env);

if (error) {
  throw new Error(`Error en las variables de entorno: ${error.message}`);
}


export const envs = {
  openaiKey: validatedEnv.REACT_APP_OPENAI_KEY,
  assistantId: validatedEnv.REACT_APP_ASSISTANT_ID,
  avatarApi: validatedEnv.REACT_APP_AVATAR_API,
  api: validatedEnv.REACT_APP_API,
  authToken: validatedEnv.REACT_APP_AUTH_TOKEN,
  bearer: validatedEnv.REACT_APP_BEARER,
};
