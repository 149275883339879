import { HomeContent } from "../../content/HomeContent/HomeContent";
import { MessagesContent } from "../../content/MessagesContent/MessagesContent";
import Profile from "../../Profile/Profile";


export const SidebarContent = ({ selectedContent }) => {
  const renderContent = () => {
    switch (selectedContent) {
      case "home":
        return <HomeContent />;
      case "messages":
        return <MessagesContent />;
      // case "users":
       //  return <Profile />;
      // case "kanban":
      //   return <KanbanContent />;
      // case "settings":
      //   return <SettingsContent />;
      default:
        return <HomeContent />;
    }
  };

  return (
    <div className="h-screen px-5 py-8 overflow-y-auto bg-white border-l border-r sm:w-64 dark:bg-gray-900 dark:border-gray-700">
      {renderContent()}
    </div>
  );
};