
import { ChatBox } from "../Chat/ChatBox";

const Home = () => {
  return (

    <div>
      <ChatBox />
    </div>
  );
};

export default Home;
