
import React from "react";
import { LuArrowUp, LuLoader } from "react-icons/lu";

export const InputBox = ({ value, onChange, onSend, isLoading }) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !isLoading) {
      onSend();

    }
  };

  return (

    <div className="relative w-full">
      <textarea
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        placeholder="Escribe tu mensaje..."
        rows="3"
        className="w-full py-4 pl-4 pr-14 border border-gray-300 rounded-3xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
      />
      <button
        onClick={onSend}
        disabled={isLoading}
        className="hidden absolute right-3 top-1/2  sm:block transform -translate-y-1/2 px-3 py-1 bg-blue-600 text-white font-semibold rounded-full shadow hover:bg-blue-700"
      >
        {isLoading ? <LuLoader size={20} /> : <LuArrowUp size={20} />}

      </button>
    </div>
  );
};
