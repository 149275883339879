export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };

  return (
    <div className="flex justify-center items-center space-x-4 mt-4">
      <button
        onClick={handlePrevious}
        className="px-3 py-1 text-gray-200 hover:text-gray-300 disabled:opacity-50"
        disabled={currentPage === 1}
      >
        {"<"}
      </button>
      <span className="text-sm font-semibold text-gray-700">
        {currentPage} 
      </span>
      <button
        onClick={handleNext}
        className="px-3 py-1  text-gray-200 hover:text-gray-300  disabled:opacity-50"
        disabled={currentPage === totalPages}
      >
        {">"}
      </button>
    </div>
  );
};
