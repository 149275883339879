import React from 'react';
import { useParams } from 'react-router-dom';
import { Message } from './Message';

const History = () => {
  const { threadId } = useParams(); // Extraer threadId desde la URL

  return (
    <div className="messages-page ">
           {/* Pasar threadId al componente Message solo si existe */}
      {threadId && <Message selectedThreadId={threadId} />}
    </div>
  );
};

export default History;
