import { envs } from "./config";

export const getToken = () => {
  return localStorage.getItem(envs.authToken);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(envs.authToken, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(envs.authToken);
};