
import React, { useState, useEffect, useRef } from "react";
import { useAuthContext } from "../../context/AuthContext";
import {
  createAndRunThread,
  getThreadStatus,
  getThreadMessages,
  saveThreadInStrapi,
  createMessage,
  startRun,
} from "../../services/api";
import { Message } from "./Messaje";
import { InputBox } from "./InputBox";
import { TypingIndicator } from "./TypingIndicator ";

export const ChatBox = () => {
  const ASSISTANT_ID = process.env.REACT_APP_ASSISTANT_ID;
  const { user } = useAuthContext();

  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [threadId, setThreadId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const [isFirstMessage, setIsFirstMessage] = useState(true);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSend = async () => {
    if (!inputValue.trim()) return;

    // Agregar el mensaje del usuario a los mensajes existentes
    let updatedMessages = [
      ...messages,
      { text: inputValue, sender: "user", id: Date.now() },
    ];
    setMessages(updatedMessages);

    // Limpiar el valor del input inmediatamente después de enviar
    setInputValue("");

    // Iniciar el estado de carga y de escritura
    setIsLoading(true);
    setIsTyping(true);

    try {
      let response;
      if (threadId) {
        await createMessage(threadId, inputValue);
        await startRun(threadId, ASSISTANT_ID);
        response = await fetchAssistantResponse(threadId);
      } else {
        const res = await createAndRunThread(ASSISTANT_ID, inputValue);
        setThreadId(res.thread_id);
        await saveThreadInStrapi(res.thread_id, res.created_at, user.id);
        response = await fetchAssistantResponse(res.thread_id);
      }

      const assistantMessage = {
        text: response,
        sender: "assistant",
        id: Date.now() + 1,
      };

      updatedMessages = [...updatedMessages, assistantMessage];
      setMessages(updatedMessages);
      setIsTyping(false);
    } catch (error) {
      console.error("Error al obtener la respuesta del asistente:", error);
    } finally {
      setIsLoading(false);
      if (isFirstMessage) setIsFirstMessage(false);
    }
  };

  const fetchAssistantResponse = async (threadId) => {
    while (true) {
      const statusData = await getThreadStatus(threadId);
      const latestRun = statusData.data[0];

      if (latestRun.status === "completed") {
        const messagesData = await getThreadMessages(threadId);
        return (
          messagesData.data[0]?.content[0]?.text?.value?.trim() ||
          "Sin respuesta."
        );
      }

      if (latestRun.status === "failed") {
        throw new Error("La solicitud falló.");
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="flex flex-col h-full bg-gray-100">
      <div
        className="flex-1 overflow-y-auto p-4 rounded-lg shadow-lg space-y-4 pb-20"
        style={{ maxHeight: "85vh" }}
      >
        {messages.map((msg) => (
          <Message key={msg.id} text={msg.text} sender={msg.sender} />
        ))}

        {isTyping && <TypingIndicator />}
        <div ref={messagesEndRef} />
      </div>

      <div
        className={`transition-all duration-500 w-full max-w-lg flex flex-col items-center  rounded-lg shadow-md ${

          isFirstMessage
            ? "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            : "fixed bottom-4 left-1/2 transform -translate-x-1/2"
        }`}

        style={{ maxHeight: "15vh" }}
      >

        {isFirstMessage && (
          <h1 className="text-center text-lg font-semibold mb-4">
            ¿Qué prueba quieres crear hoy?
          </h1>
        )}

        <div className="w-full justify-center p-4 max-w-3xl">
          <InputBox
            value={inputValue}
            onChange={handleChange}
            onSend={handleSend}
            isLoading={isLoading}
          />

        </div>
      </div>
    </div>
  );
};
