import React, { useState } from "react";
import { useAuthContext } from "../../../context/AuthContext";
import { FiLogOut } from "react-icons/fi";
import { useNavigate, Link } from "react-router-dom";
import { removeToken } from "../../../helpers";
import { IoLogoDribbble } from "react-icons/io";

const DropdownUser = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isLoading, setUser } = useAuthContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    removeToken();
    setUser(null);
    navigate("/SignIn", { replace: true });
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev); 
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <span className="text-gray-500 dark:text-gray-300">Cargando...</span>
      </div>
    );
  }

  return (
    <div className="relative">
      {user ? (
        <>
          <button
            type="button"
            onClick={toggleDropdown}
            className="flex items-center text-gray-500 dark:text-white focus:outline-none"
            aria-expanded={isOpen}
          >
            <img
              className="w-10 h-9 ml-2 rounded-full"
              src={user.avatar || "avatargorra.jpg"} 
              alt="User"
            />
          </button>
          <div
            className={`absolute right-0 z-50 mt-2 w-48 bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 ${
              isOpen ? "block" : "hidden"
            }`}
            id="dropdown-user"
          >
            <div className="px-4 py-3">
              <p className="text-sm text-gray-900 dark:text-white">
                {user.name}
              </p>
              <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300">
                {user.email}
              </p>
            </div>
            <ul className="py-1">
              <li>
                <a
                  href="/profile"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600"
                >
                  Mi Perfil
                </a>
              </li>
              <li>
                <button
                  onClick={handleLogout}
                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600"
                >
                  Cerrar Sesión
                  <FiLogOut className="ml-2" />
                </button>
              </li>
            </ul>
          </div>
        </>
      ) : (
       
        <Link
        className="px-4 py-1 text-sm text-white"
        to="https://edtools.com.co/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IoLogoDribbble size={25} />
      </Link>
      )}
    </div>
  );
};

export default DropdownUser;
