import { useEffect, useState } from "react";
import { CardThread } from "../../Thread/CardThread";
import { useAuthContext } from "../../../context/AuthContext";
import { fetchThreads } from "../../../services/api";
import Search from "antd/es/input/Search";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../../History/Pagination";

export const MessagesContent = () => {
  const [threads, setThreads] = useState([]);
  const [filteredThreads, setFilteredThreads] = useState([]);
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const userId = user?.id;

  const pageSize = 3; // Mostrar 3 threads por página
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleSelectThread = (threadId) => {
    navigate(`/history/${threadId}`); // Redirige a la ruta con el threadId
  };

  useEffect(() => {
    const fetchThreadsData = async () => {
      if (!userId) return;
  
      const data = await fetchThreads(userId); // Nota: quitamos `currentPage` y `pageSize`
      if (data?.data) {
        const nonArchivedThreads = data.data.filter(
          (thread) => thread.attributes.status !== "archived"
        );
        setThreads(nonArchivedThreads);
  
        // Calcula las páginas dinámicamente
        const calculatedTotalPages = Math.ceil(nonArchivedThreads.length / pageSize);
        setTotalPages(calculatedTotalPages);
  
        // Muestra los threads de la primera página
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        setFilteredThreads(nonArchivedThreads.slice(startIndex, endIndex));
      } else {
        setThreads([]);
        setFilteredThreads([]);
        setTotalPages(1);
      }
    };
  
    fetchThreadsData();
  }, [userId, currentPage]);
  
  

  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();
    const filtered = threads.filter((thread) =>
      thread.attributes.id_value?.toLowerCase().includes(searchValue)
    );
    setFilteredThreads(filtered); // Actualiza los threads filtrados
    setTotalPages(Math.ceil(filtered.length / pageSize)); // Calcula las páginas basadas en la búsqueda
    setCurrentPage(1); // Reinicia la paginación
  };

  const handleUpdateThreadName = (threadId, newName = null, newStatus = null) => {
    setThreads((prevThreads) =>
      prevThreads.map((thread) =>
        thread.id === threadId
          ? {
              ...thread,
              attributes: {
                ...thread.attributes,
                ...(newName !== null && { name: newName }),
                ...(newStatus !== null && { status: newStatus }),
              },
            }
          : thread
      )
    );
  };

  return (
    <div className="messages-content">
      <label className="px-3 text-xs text-gray-500 uppercase dark:text-gray-400 flex flex-col items-center text-center justify-center p-8">
        Historial de Conversaciones
      </label>
      <Search
        placeholder="Buscar por thread"
        onSearch={handleSearch}
        className="mb-5"
      />
      <div className="threads-container">
        {filteredThreads.map((thread) => (
          <CardThread
            key={thread.id}
            thread={thread}
            formatDate={(dateString) => new Date(dateString).toLocaleString()}
            onUpdateThreadName={handleUpdateThreadName}
            onSelectThread={() => handleSelectThread(thread.attributes.id_value)} // Redirige al hacer clic
          />
        ))}
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};
