import { Link } from "react-router-dom";

export const HomeContent = () => {
    return (
      <div className="flex flex-col justify-center items-center min-h-full ">
        <div className="text-center ">
          <h1 className="text-lg font-semibold text-white">Bienvenido a Edtools</h1>
          <p className="text-slate-400 mt-2">Diagnósticos, evaluaciones, pruebas profesionales con IA</p>
        </div>
        <div className="flex flex-col items-center justify-center space-y-6 mt-8">
          <h2 className="text-md text-white">Comienza con la innovación</h2>
          <Link to="/">
          <button href="/" className="px-3 py-2 text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300">
            Crear Prueba
          </button>
          </Link>
        </div>
      </div>
    );
  };
  